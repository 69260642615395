import React from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import profilephoto from "./images/profile-photo.JPG";
import quote1 from "./images/quote1.png";
import quote4 from "./images/quote4.png";
import career1 from "./images/career1.png";
import career2 from "./images/career2.png";

import {
  faGithub,
  faLinkedin,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Nucamp from "./components/NucampCarousel";
import Citation from "./components/CitationCarousel";
import Sirliving from "./components/SirlivingCarousel";

import "bootstrap/dist/css/bootstrap.min.css";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <body className="parent-home">
        <aside className="sidebar">
          <div className="sidebar-inner-card">
            <a href="/" rel="profile">
              <div className="profile-photo">
                <img src={profilephoto} alt="Oanh Bui"/>
              </div>
            </a>
            <div className="profile-content">
              <h1>Oanh Bui</h1>
              <h2>Front-end Software Engineer</h2>
            </div>
            <div className="navigation">
              <a href="/">portfolio</a>
              <a href="/">contact me</a>
              <a href="/">resume</a>
            </div>
            <div className="get-in-touch">
              <p>get in touch</p>
              <a href="/">
                <FontAwesomeIcon className="icon" icon={faEnvelope} />
              </a>
              <a href="https://www.linkedin.com/in/oanhbp/">
                <FontAwesomeIcon className="icon" icon={faLinkedin} />
              </a>
              <a href="https://github.com/oanhbui">
                <FontAwesomeIcon className="icon" icon={faGithub} />
              </a>
              <a href="https://www.facebook.com/buzz.oanh">
                <FontAwesomeIcon className="icon" icon={faFacebookF} />
              </a>
            </div>
          </div>
        </aside>
        <div className="info-page">
          <div className="container">
            <div className="row info-card about-me">
              <h3>about me</h3>
              <div className="col-sm-2"></div>
              <div className="col-sm info-details">
                <p>
                  Hi, my name is Oanh Bui (Joann). I'm a new graduate student
                  specializing in Front-end Website development looking for
                  internship/entry-level positions. I love to make everything
                  looks prettier and bring out the most wonderful experience for
                  myself and people around me.
                </p>
              </div>
            </div>
            <div className="row info-card skill">
              <h3>Skills</h3>
              <div className="col-sm-2"></div>
              <div className="col-sm info-details">
                <ul>
                  <li>HTML</li>
                  <li>CSS</li>
                  <li>JavaScript</li>
                  <li>ReactJS</li>
                  <li>Bootstrap</li>
                  <li>NodeJS</li>
                  <li>VueJS</li>
                  <li>English, Korean, Vietnamese</li>
                </ul>
              </div>
            </div>
            <div className="row info-card education-experience">
              <h3>education & experiences</h3>

              <div className="col-sm-2">2022/10</div>
              <div className="col-sm info-details">
                <h4>SIRLiving Properties booking Website</h4>
                <p>
                  Freelancer
                  <br />
                  Front-End Software Engineer
                </p>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 detail-text">
                      <li>Building responsive property rental website</li>
                      <li>
                        Collecting user's informations and booking details using
                        React Fomik and communicate with server-side
                      </li>
                      <i>
                        Techniques: HTML, ReactJS, CSS, Bootstrap, Reactstrap,
                        NextJS, Fomik
                      </i>
                    </div>
                    <div className="col-sm">
                      <Sirliving />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row info-card leafgroup">
              <div className="col-sm-2">2022/09 - present</div>
              <div className="col-sm info-details">
                <h4>Leaf Group</h4>
                <p>
                  Internship
                  <br />
                  Front-End Software Engineer
                </p>
                <div className="container">
                  <div className="row internship">
                    <div className="col-sm-6 detail-text">
                      <li>
                        Building Quote Images Maker Application with pipeline to
                        automatically generate images with choice of image type.
                      </li>
                      <i>Techniques: HTML, CSS, JavaScript, Playwright</i>
                    </div>
                    <div className="col-sm">
                      <img src={quote1} />
                      <img src={quote4} />
                    </div>
                  </div>
                  <div className="row internship">
                    <div className="col-sm-6 detail-text">
                      <li>
                        getting to learn and pratice with UI framework VueJS:
                        building front-end site for Citation Maker Application.
                      </li>
                      <i>Techniques: VueJS, CSS </i>
                    </div>
                    <div className="col-sm">
                      <Citation />
                    </div>
                  </div>
                  <div className="row internship">
                    <div className="col-sm-6 detail-text">
                      <li>
                        getting to learn about CSS-doodle, tried out with some
                        self-made background image and use them to make
                        infographic about career. Automatically generate with
                        built pipeline to make content standing out in Google
                        Image Search Engine.
                      </li>
                      <i>
                        Techniques: CSS-doodle, CSS, HTML, JavaScript,
                        Playwright
                      </i>
                    </div>
                    <div className="col-sm">
                      <img src={career1} />
                      <img src={career2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row info-card nucamp">
              <div className="col-sm-2">2021-2022</div>
              <div className="col-sm info-details">
                <h4>Nucamp Coding Bootcamp</h4>
                <p>
                  Website Development Fundamentals
                  <br />
                  Fullstack Website and Mobile App Development
                </p>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6 detail-text">
                      <li>Fundamentals: HTML, CSS, Javascript</li>
                      <li>
                        Front-End Web UI framework (Bootstrap) and library
                        (React)
                      </li>
                      <li>
                        Multiplatform Mobile App Development with React Native
                      </li>
                      <li>
                        Back-End Development with NodeJS, Express and MongoDB
                      </li>
                      <li>Building Nucampsite Website</li>
                    </div>
                    <div className="col-sm">
                      <Nucamp />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row info-card koreanmaster">
              <div className="col-sm-2">2015-2017</div>
              <div className="col-sm info-details">
                <h4>Academy of Korean Studies</h4>
                <p>South Korea<br/>Master Degree of Korean Linguistics</p>
              </div>
            </div>
          </div>
        </div>
      </body>
    );
  }
}
